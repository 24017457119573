header {
  width: 100%;
  height: 70px;
  line-height: 70px;
  background-color: whitesmoke;
}

.nav__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo a {
  display: flex;
  align-items: center;
  justify-content: end;
  column-gap: 8px;
}

.logo img {
  height: 2rem;
}

.logo h1 {
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--primary-color);
}

.menu {
  display: flex;
  column-gap: 2.7rem;
  margin-bottom: 0;
}

.nav__item a {
  color: var(--primary-color);
  font-weight: 500;
  cursor: pointer;
}

.nav__icons {
  display: flex;
  column-gap: 2rem;
  justify-content: center;
}

.nav__icons span i {
  font-size: 1.4rem;
  color: var(--primary-color);
}

.cart__icon {
  transform: scale(1.1);
}

.cart__icon,
.fav__icon {
  position: relative;
  cursor: pointer;
  margin-top: 4px;
}

.badge {
  position: absolute;
  top: 18%;
  right: -50%;
  width: 22px;
  height: 18px;
  content: "";
  background: var(--primary-color);
  color: #fff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  font-weight: 600;
  z-index: 10;
}

.nav__icons span img {
  width: 30px;
  height: 30px;
}

.mobile__menu {
  font-size: 1.3rem;
  color: var(--primary-color);
  display: none;
}

.nav__active {
  font-weight: 700 !important;
}

.sticky__header {
  width: 100%;
  height: 70px;
  line-height: 70px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  box-shadow: 3px 3px 8px -3px rgb(44, 192, 245);
  background: rgb(44, 192, 245);
}

@media only screen and (max-width: 768px) {
  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999;
    display: none;
  }

  .menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background: #fff;
    z-index: 9999999;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .active__menu {
    display: block;
  }

  .mobile__menu {
    display: block;
  }
}
