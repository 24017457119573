.product__item {
  cursor: pointer;
}

.product__info h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 15px;
  text-align: end;
}

.product__info span {
  font-size: 0.9rem;
}

.product__info a:hover {
  color: var(--primary-color);
}

.product__card-bottom {
  padding-top: 0px !important;
}

.product__card-bottom .price {
  color: var(--primary-color);
  font-size: 1.3rem;
  font-weight: 500;
}

.product__card-bottom .old {
  text-decoration: underline;
  text-underline-offset: -55%;
  text-decoration-skip-ink: none;
  font-size: 1.2rem;
  padding-left: 5px;
  color: red;
}

.product__card-bottom span i {
  font-size: 1.2rem;
  padding: 12px 15px 13px 10px;
  background: var(--primary-color);
  color: #fff;
  border-radius: 50px;
  margin-right: 15px;
}

.product__card-bottom .disabled i {
  font-size: 1.2rem;
  padding: 10px 15px 11px 10px;
  background: gray !important;
  color: #fff;
  border-radius: 50px;
  margin-right: 15px;
  cursor: default !important;
}

.product__card-bottom span i::before {
  margin-right: 5px;
}

@media only screen and (max-width: 1400px) {
  .product__card-bottom span i {
    font-size: 15px;
  }

  .product__card-bottom .disabled i {
    font-size: 15px;
  }

  .product__card-bottom .price {
    font-size: 20px;
  }

  .product__card-bottom .old {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1200px) {
  .product__card-bottom span i {
    font-size: 14px;
    padding: 7px 10px 7px 7px;
    background: var(--primary-color);
    color: #fff;
    border-radius: 50px;
  }

  .product__card-bottom .disabled i {
    font-size: 14px;
  }

  .product__card-bottom span i::before {
    margin-right: 5px;
  }

  .product__card-bottom .price {
    font-size: 15px;
  }

  .product__card-bottom .old {
    font-size: 12px;
  }

  .product__card-bottom {
    padding-top: 0px !important;
    padding-left: 0px !important;
  }
}

@media only screen and (max-width: 760px) {
  .product__card-bottom span i {
    font-size: 1.2rem;
    padding: 10px 15px 11px 10px;
    background: var(--primary-color);
    color: #fff;
    border-radius: 50px;
  }

  .product__card-bottom .disabled i {
    font-size: 1.2rem;
  }

  .product__card-bottom span i::before {
    margin-right: 5px;
  }

  .product__card-bottom .price {
    font-size: 1.4rem;
  }

  .product__card-bottom .old {
    font-size: 1rem;
  }

  .product__card-bottom {
    padding-top: 0px !important;
    padding-left: 15px !important;
  }
}
