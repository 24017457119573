.billing__form .form__group input {
  border: 1px solid var(--card-bg-02);
}

.billing__form .form__group input:required:valid {
  border-color: green;
}

.billing__form .form__group input:invalid {
  border-color: red;
}

.checkout__cart {
  padding: 20px;
  background: var(--primary-color);
  color: white;
  border-radius: 5px;
}

.checkout__cart h6,
h4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkout__cart h6 {
  margin-bottom: 20px;
}

.checkout__cart h4 {
  border-top: 1px solid rgba(221, 221, 221, 0.253);
  padding-top: 20px;
}

.checkout__cart button {
  margin-top: 52px;
  margin-bottom: 10px;
  background-color: white !important;
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  border-radius: 50px !important;
  padding: 10px 25px;
  font-size: 1.4rem;
  font-weight: 600;
}

.error-msgs {
  color: red;
  font-size: 0.9rem;
  margin-top: 7px;
}
