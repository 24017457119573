row {
  align-items: center;
}

.filter__widget select {
  padding: 8px 40px 8px 60px;
  border: 1px solid var(--primary-color);
  cursor: pointer;
  border-radius: 5px;
  background: var(--primary-color);
  color: white;
  background-image: url("../assets/images/sush/arrow-down-s-line.png");
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position-x: 95%;
  background-position-y: 10px;
}

.filter__widget select:focus {
  outline: none !important;
}

.filter__widget select option {
  font-size: 0.9rem;
  text-align: end;
  margin-right: 10px;
}

.search__box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding-right: 12px;
  padding: 0 12px 0 2px;
  cursor: pointer;
}

.search__box input {
  width: 100%;
  border: none;
  outline: none;
  padding: 8px 10px;
}

.search__box span {
  color: var(--primary-color);
}

@media only screen and (max-width: 992px) {
  .filter__widget {
    margin-bottom: 30px;
  }

  .filter__widget select {
    font-size: 0.9rem;
  }
}
