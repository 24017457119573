.service__item {
  padding: 20px;
  background: var(--card-bg-01);
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  column-gap: 0.7rem;
  border-radius: 5px;
  cursor: pointer;
}

.service__item span i {
  font-size: 2.2rem;
  background: var(--primary-color);
  padding: 10px;
  border-radius: 50px;
  color: #fff;
  font-weight: 400 !important;
}

.service__item h3 {
  color: var(--primary-color);
  font-size: 1.1rem;
  font-weight: 600;
}

.service__item p {
  font-size: 0.9rem;
  margin-top: 5px;
  color: #222;
}

@media only screen and (max-width: 768px) {
  .service__item {
    margin-bottom: 1.5rem;
  }

  .service__item span i {
    font-size: 1.5rem;
    padding: 5px;
  }
}
